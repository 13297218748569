import { RenderByPermission, SearchListModal, request } from '@/utils';
import type { BaseData, IMainSubStructureModel, NormalProgrammeParams } from '@/utils';
import { Button, Modal, Space, Tag, message } from 'antd';
import { action, observable } from 'mobx';
import React from 'react';
import AlternativeModel from './alternativeSlice/model';
import { API } from './api';
import { userTypeDict } from './dict';
import DetailModel from './employerDetail/model';
import { MockListData } from './mock';

const { confirm } = Modal;

export default class Model {
  // 雇主明细弹窗
  public employerDetailStore = new DetailModel(this);

  // 备选切片弹窗
  public alternativeSliceStore = new AlternativeModel(this);

  constructor() {
    this.mainStore.grid.onQuery();
  }

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        field: 'name',
        label: '雇主昵称',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'phone',
        label: '手机号',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'butlerName',
        label: '管家姓名',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'bdName',
        label: 'BD姓名',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'companyName',
        label: '公司名称',
        placeholder: '请输入',
      },
      {
        type: 'select',
        field: 'userType',
        label: '服务类型',
        data: [
          {
            label: '未付费',
            value: '0',
          },
          {
            label: '服务期内',
            value: '1',
          },
          {
            label: '服务过期',
            value: '2',
          },
        ],
      },
      {
        type: 'select',
        field: 'status',
        label: '状态',
        data: [
          {
            label: '封禁',
            value: '1',
          },
          {
            label: '正常',
            value: '0',
          },
        ],
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    buttons: [],
    grid: {
      rowHeight: (row) => {
        const subscribeAnchorRuleVO = row.row.subscribeAnchorRuleVO;
        // subscribeAnchorRuleVO可能为空值
        if (subscribeAnchorRuleVO && Object.keys(subscribeAnchorRuleVO).length) {
          return 100;
        }
        return 40;
      },
      columns: [
        {
          key: 'opreations',
          name: '操作',
          frozen: true,
          width: 100,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_21`}>
                  <a
                    className="px-1"
                    onClick={() => {
                      this.employerDetailStore.onOpenDetailModal(row.id);
                    }}
                    type="link"
                  >
                    详情
                  </a>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_46`}>
                  <a
                    className="px-1"
                    onClick={() => {
                      this.onEnabel(row.id, row.status === 1 ? 0 : 1);
                    }}
                    type="link"
                  >
                    {row.status === 1 ? '解封' : '封禁'}
                  </a>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'name',
          name: '雇主昵称',
          width: 150,
        },
        {
          key: 'userType',
          name: '用户类型',
          width: 100,
          formatter: ({ row }) => userTypeDict[row?.userType] || '',
        },
        {
          key: 'sysTagVOList',
          width: 200,
          name: '雇主标签',
          formatter: ({ row }) => {
            const iconList = row?.sysTagVOList || [];
            return iconList.map(({ libId, libName }) => (
              <Tag
                color="green"
                key={libId}
              >
                {libName}
              </Tag>
            ));
          },
        },
        {
          key: 'phone',
          width: 100,
          name: '绑定手机号',
        },
        {
          key: 'butlerName',
          name: '管家姓名',
          width: 100,
        },
        {
          key: 'bdName',
          name: 'BD姓名',
          width: 100,
        },
        {
          key: 'companyName',
          name: '公司名称',
          width: 150,
        },
        // TODO: 翻译
        {
          key: 'subscribeAnchorRuleVO',
          name: '订阅信息',
          width: 200,
          formatter: ({ row }) => {
            const { cityList, liveTypeList, minGmv, maxGmv, minPeople, maxPeople } = row.subscribeAnchorRuleVO || {};

            return (
              <Space className="flex  flex-col  items-start h-full">
                {cityList?.length ? (
                  <p className="leading-15">
                    工作城市：
                    {cityList.map((v) => (
                      <Tag key={v.id}>{v.name}</Tag>
                    ))}
                  </p>
                ) : null}
                {liveTypeList?.length ? (
                  <p className="leading-15">
                    直播品类：
                    {liveTypeList.map((v) => (
                      <Tag key={v.id}>{v.name}</Tag>
                    ))}
                  </p>
                ) : null}
                {minGmv || maxGmv ? (
                  <p className="leading-15">
                    单场最高GMV：{minGmv || '未知'} - {maxGmv || '未知'}
                  </p>
                ) : null}
                {minPeople || maxPeople ? (
                  <p className="leading-15">
                    单场同时在线人数：{minPeople || '未知'} - {maxPeople || '未知'}
                  </p>
                ) : null}
              </Space>
            );
          },
        },
        {
          key: 'slicePeopleCount',
          name: '备选人数',
          width: 80,
          formatter: ({ row }) => (
            <a
              className="text-primary"
              onClick={() => {
                this.alternativeSliceStore.onOpenModal(row.id);
                this.alternativeSliceStore.setSliceModalTitleInfo({
                  name: row.name,
                  slicePeopleCount: row.slicePeopleCount,
                  sliceCount: row.sliceCount,
                });
              }}
            >
              {row.slicePeopleCount | 0}人
            </a>
          ),
        },
        {
          key: 'positionCount',
          name: '职位数/上线职位数',
          width: 150,
          formatter: ({ row }) => `${row.positionCount}个/${row.onLinePositionCount}个`,
        },
        {
          key: 'inPersonCount',
          name: '到面次数/赠送次数',
          width: 150,
          formatter: ({ row }) => `${row.inPersonCount}次/${row.giftCount}个`,
        },
        {
          key: 'status',
          name: '账号状态',
          width: 100,
          formatter: ({ row }) => {
            return row.status === 1 ? <Tag color="red">封禁</Tag> : <Tag color="green">正常</Tag>;
          },
        },

        {
          key: 'sourceName',
          name: '来源',
          width: 100,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params: Record<string, any>) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };

        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        if (postParams.createTime) {
          postParams.createTimeBegin = postParams.createTime.split(',')[0];
          postParams.createTimeEnd = postParams.createTime.split(',')[1];
          // biome-ignore lint/performance/noDelete: <explanation>
          delete postParams.createTime;
        }
        return request<BaseData<any>>({
          url: API.queryEmployerPage,
          method: 'POST',
          data: { ...postParams },
        });
        // return Promise.resolve({ ...MockListData } as any);
      },
    },
  };

  public mainStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  // 启用禁用
  @action
  private onEnabel = (userId, status: 1 | 0) => {
    confirm({
      title: '操作提示',
      content: `是否${status === 1 ? '封禁' : '解封'}该雇主账号？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const result = await request<BaseData>({
          url: API.enabelCount,
          method: 'POST',
          data: { userId, status },
        });
        message.success(result.msg || '操作成功');
        this.mainStore.grid.onQuery();
      },
    });
  };
}
