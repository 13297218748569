import type { FormInstance } from 'antd';
import { Button, Modal, Space, Tag, message } from 'antd';
import { action, observable, toJS } from 'mobx';
import React from 'react';
import { type BaseData, type PaginationData, type PureData, getIntersection, request } from '../../../../utils';
import CompanyEditorModel from '../../companyInfo/companyEditor/model';
import { API } from '../api';
import { userTypeDict } from '../dict';
import { MockDetailData } from '../mock';
import type Model from '../model';
import type { LiveTypeListVO, SysEmployerInfoVO, SysTagLibSimpleVO, SysTagVO } from '../type';

const { confirm } = Modal;

export default class DetailModel {
  @observable public detailData: SysEmployerInfoVO = {};

  @observable public ref: FormInstance;

  // 控制详情弹窗里的编辑态
  @observable public isEdit = false;

  @observable public isOpen = false;

  // 热门城市列表
  @observable public hotCityList: Array<{ id: string; name: string; sort: number }> = [];

  // 直播所有标签列表
  @observable public tagTree: SysTagLibSimpleVO[] = [];
  // 直播类目列表
  @observable public liveTypeTree: LiveTypeListVO[] = [];
  // 直播类目列表暂存数据
  @observable public liveTypeTreeTemp: Record<string, LiveTypeListVO> = {};

  public companyEditorModel = new CompanyEditorModel();
  constructor(parentStore: Model) {
    this.parentStore = parentStore;
  }

  public parentStore: Model;

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  // 获取雇主信息
  @action
  public getDetailInfo = async (id: any) => {
    const result = await request<{ code: number; msg?: string; data: SysEmployerInfoVO }>({
      url: API.getDetailInfo,
      method: 'POST',
      data: { id },
    });
    console.log(result, '雇主信息');
    const { name, contactMobile, wxCode, subscribeAnchorRuleVO = {}, sysTagVOList = [] } = result.data;
    this.detailData = result.data;
    // 回写数据
    // 从subscribeAnchorRuleVO取，cityList，liveTypeList，maxGmv，maxPeople，minGmv，minPeople，form.setFeildsValues
    // cityList遍历取一级数据，liveTypeList遍历取一级数据，若childrenList为空，则只赋值一级数据，否则应该是这样的数据结构[[1],[2,1],[3,4]]
    const { maxGmv, maxPeople, minGmv, minPeople, cityList, liveTypeList } = subscribeAnchorRuleVO || {};
    const liveTypeListData = liveTypeList?.reduce((pre, cur) => {
      const temp = cur.childrenList ? cur.childrenList.map((v) => [cur.id, v.id]) : [[cur.id]];
      pre.push(...temp);
      return pre;
    }, []);
    console.log(liveTypeListData, 'liveTypeListData');
    this.ref.setFieldsValue({
      name,
      contactMobile,
      wxCode,
      cityList: cityList?.map((v) => v?.id),
      liveTypeList: liveTypeListData,
      maxGmv,
      maxPeople,
      minGmv,
      minPeople,
      sysTagVOList: sysTagVOList?.map((v) => {
        return { label: v.libName, value: v.libId };
      }),
    });
  };
  // 获取热门城市列表
  @action
  public getCityList = async () => {
    const result = await request<BaseData<PureData<{ id: string; name: string; sort: number }>>>({
      url: API.cityList,
      method: 'POST',
      data: { pageNum: 1, pageSize: 100 },
    });
    console.log(result, '获取热门城市列表');
    const list = result?.data?.list || [];
    list.unshift({ id: '-1', name: '不限', sort: 0 });
    this.hotCityList = list;
  };
  // 获取所有列表标签
  @action
  public getTagList = async () => {
    const result = await request<BaseData<SysTagLibSimpleVO[]>>({
      url: API.tagList,
      method: 'GET',
      params: { categoryType: 0 },
    });
    const list = result?.data || [];
    // 过滤掉没有子节点的数据，否则会选择到分类
    const filterList = list.filter((v) => v.children.length !== 0);
    const treeData = filterList.map((v) => {
      return {
        ...v,
        pid: v.parentId,
      };
    });
    console.log(treeData, '获取所有列表标签');
    this.tagTree = treeData;
  };
  // 直播类型列表
  @action
  public getLiveTypeList = async () => {
    const result = await request<BaseData<LiveTypeListVO[]>>({
      url: API.liveTypeList,
      method: 'POST',
      data: {},
    });
    const list = result?.data || [];
    const treeData = list.map((v) => {
      return {
        ...v,
        pid: v.parentId,
      };
    });
    this.liveTypeTree = treeData;
    this.liveTypeTreeTemp = treeData.reduce((pre, cur) => {
      return {
        [cur.id]: cur,
        ...pre,
      };
    }, {});
    console.log(treeData, this.liveTypeTreeTemp, '直播类型列表');
  };

  // 点击弹窗编辑按钮
  @action public onEdit = () => {
    this.isEdit = true;
  };
  // 打开详情弹窗
  @action public onOpenDetailModal = async (employerId: number) => {
    this.isOpen = true;
    if (!this.hotCityList.length) {
      await this.getCityList();
      await this.getLiveTypeList();
      await this.getTagList();
    }
    this.getDetailInfo(employerId);
  };
  // 关闭详情弹窗
  @action public onCancel = () => {
    this.isOpen = false;
    this.isEdit = false;
    // TODO:重置表单数据
    this.ref.resetFields();
    this.detailData = {};
  };

  // 打开切片弹窗
  @action public onOpenSliceModal = async () => {
    this.parentStore.alternativeSliceStore.onOpenModal(this.detailData.id);
    this.parentStore.alternativeSliceStore.setSliceModalTitleInfo({
      name: this.detailData.name,
      slicePeopleCount: this.detailData.slicePeopleCount,
      sliceCount: this.detailData.sliceCount,
    });
  };

  // 保存详情
  @action public onSave = async (): Promise<void> | undefined => {
    await this.ref.validateFields();
    const values = this.ref.getFieldsValue();
    const { contactMobile, name, wxCode, maxGmv, minGmv, maxPeople, minPeople, cityList, liveTypeList } = values;
    const { companyId, id } = this.detailData;

    if ((maxGmv && !minGmv) || (minGmv && !maxGmv)) {
      message.error('请填写完整的【单场最高GMV】区间');
      return;
    }
    if ((maxPeople && !minPeople) || (minPeople && !maxPeople)) {
      message.error('请填写完整的【单场同时在线人数】区间');
      return;
    }

    const subscribeAnchorRuleDTO: Record<string, any> = {};
    if (maxGmv || minGmv || maxPeople || minPeople) {
      Object.assign(subscribeAnchorRuleDTO, {
        maxGmv,
        minGmv,
        maxPeople,
        minPeople,
      });
    }
    if (cityList?.length && cityList.indexOf('-1') === -1) {
      const tempCityList = new Set(cityList);
      subscribeAnchorRuleDTO.cityList = toJS(this.hotCityList.filter((item) => tempCityList.has(item.id)));
    }
    const { liveTypeTreeTemp } = this;
    console.log(liveTypeList, toJS(liveTypeTreeTemp), values, '----');
    const rqLiveTypeList: LiveTypeListVO[] = [];
    liveTypeList?.length &&
      liveTypeList.forEach((item) => {
        if (item.length === 1) {
          // 格式为[x],x代表一级分类；格式为[x,y],x为一级分类，y为二级节点。这么处理是为了兼容小程序端数据格式。后续修改这一部分一定要确认好再做修改
          const [pid] = item;
          rqLiveTypeList.push({
            id: liveTypeTreeTemp[pid].id,
            name: liveTypeTreeTemp[pid].name,
            childrenList: null,
          });
        } else {
          const [pid, cid] = item;
          const parentItem = rqLiveTypeList.find((v) => v.id === pid);
          const child = liveTypeTreeTemp[pid].childrenList.find((v) => v.id === cid);
          if (parentItem) {
            parentItem.childrenList.push({
              id: child.id,
              name: child.name,
            });
          } else {
            rqLiveTypeList.push({
              id: pid,
              name: liveTypeTreeTemp[pid].name,
              childrenList: [{ id: child.id, name: child.name }],
            });
          }
        }
      });
    if (liveTypeList) {
      subscribeAnchorRuleDTO.liveTypeList = rqLiveTypeList;
    }
    values.subscribeAnchorRuleDTO = subscribeAnchorRuleDTO;
    const sysTagVOList: SysTagVO[] = [];
    values?.sysTagVOList?.forEach((v) => {
      sysTagVOList.push({
        bizId: id,
        libId: v.value,
      });
    });
    const postData = {
      companyId,
      contactMobile,
      id,
      name,
      subscribeAnchorRuleDTO,
      sysTagVOList,
      wxCode,
    };
    console.log(values, postData, 'this.ref保存信息');
    const result = await request<BaseData>({
      url: API.updateEmployer,
      method: 'POST',
      data: postData,
    });
    console.log(result, '保存信息反馈');
    result.code === 200 && message.success('保存成功');
    this.parentStore.mainStore.grid.onQuery();
    this.onCancel();
  };

  // 校验最大值最小值
  public validateLessThan = (_: any, value: string, callback: (message?: string) => Promise<void>, param) => {
    if (value && Number.parseFloat(value) > Number.parseFloat(this.ref.getFieldValue(param))) {
      callback('不能大于最大值');
    } else {
      callback();
    }
  };

  // 校验最大值最小值
  public validateMoreThan = (_: any, value: string, callback: (message?: string) => Promise<void>, param) => {
    if (value && Number.parseFloat(value) < Number.parseFloat(this.ref.getFieldValue(param))) {
      callback('不能小于最小值');
    } else {
      callback();
    }
  };

  @action companyEditorCallBack = (params: Record<string, any>) => {
    console.log(params, '编辑保存回调');
    if (params?.name) {
      this.detailData.companyName = params.name;
    }
  };

  @action public openEditCompany = () => {
    this.companyEditorModel.saveCallBack = this.companyEditorCallBack;
    this.companyEditorModel.onOpen(String(this.detailData.companyId));
    this.companyEditorModel.isEdit = true;
  };
}
